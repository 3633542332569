<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-3">
      <div class="card card-body">
        <h1 class="text-info">{{ stats.devices.length }}</h1>
        <small><i class="fa fa-mobile"></i> أجهزتك </small>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <div class="card card-body">
        <h1 class="text-primary">{{ stats.today_messages }}</h1>
        <small><i class="fa fa-paper-plane"></i> رسالة مرسلة اليوم </small>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <div class="card card-body">
        <h1 class="text-warning">{{ stats.pending_messages }}</h1>
        <small><i class="fa fa-clock-o"></i> جاري الارسال </small>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <div class="card card-body">
        <h1 class="text-secondary">{{ stats.contacts }}</h1>
        <small><i class="fa fa-users"></i> جهة اتصال في حسابك </small>
      </div>
    </div>
    <template v-if="stats.subscribe">
      <div class="col-12 c g" v-if="stats.subscribe.plan == 0">
        <div class="g">
          <div class="card card-body">
            <div class="row">
              <div class="col-12 col-lg-1 d-none d-lg-block">
                <br />
                <img
                  :src="require('@/assets/images/rocket.png')"
                  style="width: 100%"
                  alt=""
                />
              </div>
              <div class="col-12 col-lg-11">
                <p>معلومات الإشتراك</p>
                <h5>
                  الخطة:
                  <span v-if="stats.subscribe.plan == 0" class="text-danger"
                    >فترة تجريبية</span
                  >
                  <span v-if="stats.subscribe.plan == 1">خطة البداية</span>
                  <span v-if="stats.subscribe.plan == 2">الخطة الفضية</span>
                  <span v-if="stats.subscribe.plan == 3">الخطة الذهبية</span>
                </h5>
                <h5 v-if="stats.subscribe.demo">
                  الرسائل المتبقية في النسخة التجريبية :
                  {{
                    stats.subscribe.messages_sent >= 10
                      ? 0
                      : 10 - stats.subscribe.messages_sent
                  }}
                </h5>
                <h5 v-if="!stats.subscribe.demo">
                  تاريخ انتهاء الاشتراك: {{ stats.subscribe.exdate }}
                </h5>
                <h5 v-if="!stats.subscribe.demo">
                  عدد الاجهزة المسموح به: {{ stats.subscribe.allowed_devices }}
                </h5>
                <button
                  class="btn btn-success btn-lg"
                  v-if="stats.subscribe.demo"
                  @click="$router.push('/subscribe')"
                >
                  اشتراك الآن
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 c col-lg-8 g" v-if="stats.subscribe.plan != 0">
        <div class="g">
          <div class="card card-body">
            <img
              :src="require('@/assets/images/notice.png')"
              style="width: 100%; border-radius: 10px"
              alt=""
            />
          </div>
        </div>
      </div>
    </template>
    <div class="col-12">
      <p class="text-muted">أجهزتك</p>
    </div>
    <div class="col-12">
      <div class="row">
        <div
          class="col-12 col-lg-3"
          v-for="device in stats.devices"
          :key="device"
        >
          <div class="card card-body text-center">
            <div class="row">
              <div class="col-4 d-none d-lg-block">
                <img
                  :src="require('@/assets/images/phone.png')"
                  style="width: 100%; margin: 0px auto"
                  alt=""
                />
              </div>
              <div class="col-12 col-lg-8 text-left">
                <h4
                  style="
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                  "
                >
                  {{ device.title }}
                  <br />
                  <small class="text-muted">{{ device.phone }}</small>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      stats: {
        devices: [],
      },
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      var g = this;
      $.post(api + "/user/devices/stats", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          g.stats = r.response;
          g.loading = false;
        })
        .catch(function () {
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة في الاتصال",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>
